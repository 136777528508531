<template>
    <div class="dishes-container">
        <h1 class="title">{{ $t('dishes.title') }}</h1>
        <FilterSection class="filter-section" />
        <CRButton @click="isCreateModalOpen = true">
            {{ $t('buttons.addNewDish') }}
        </CRButton>

        <div v-if="!dishes.length" class="empty__wrapper">
            <h1>{{ $t('dishes.noDishesAdded') }}</h1>
        </div>
        <div v-else class="card__wrapper">
            <DishCard class="card" v-for="dish in dishes" :key="dish.id" :dish="dish" @update="openUpdateModal" />
        </div>
        <Pagination
            v-if="dishes.length"
            :current-page="meta.currentPage"
            :page-count="meta.pageCount"
            :total-count="meta.totalCount"
            :per-page="meta.perPage"
            @pageNum="changePage"
        />
        <DishModals
            :isCreateModalOpen="isCreateModalOpen"
            :isDeleteModalOpen="isDeleteModalOpen"
            :editedDish="editedDish"
            :isEditMode="isEditMode"
            @deleteDish="deleteDish"
            @closeDeleteModal="closeDeleteModal"
            @openDeleteModal="openDeleteModal"
            @closeModal="closeModal"
            @saveDish="saveDish"
        />
    </div>
</template>

<script>
    import FilterSection from '@/components/Admin/Dishes/FilterSection.vue';
    import DishCard from '@/components/Admin/Dishes/DishCard.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import Pagination from '@/components/Common/Pagination/Pagination.vue';
    import DishModals from '@/components/Admin/Dishes/DishModals.vue';
    import { mapGetters, mapActions, mapMutations } from 'vuex';

    export default {
        name: 'Dishes',
        components: {
            FilterSection,
            DishCard,
            CRButton,
            Pagination,
            DishModals,
        },
        data() {
            return {
                isCreateModalOpen: false,
                isDeleteModalOpen: false,
                isEditMode: false,
                editedDish: null,
            };
        },
        computed: {
            ...mapGetters('dish', ['dishes', 'meta']),
        },
        methods: {
            ...mapActions('dish', ['getAllDishes', 'destroyDish']),
            ...mapMutations('dish', ['addDish', 'updateDishById', 'deleteDishById']),

            async changePage(pageNum) {
                try {
                    await this.getAllDishes({ page: pageNum });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            openUpdateModal(dish) {
                this.isEditMode = true;
                this.editedDish = dish;
                this.isCreateModalOpen = true;
            },

            async deleteDish() {
                this.destroyDish(this.editedDish.id);

                this.editedDish = null;
                this.isDeleteModalOpen = false;
            },

            closeDeleteModal() {
                this.isDeleteModalOpen = false;
                this.isCreateModalOpen = true;
            },

            openDeleteModal() {
                this.isDeleteModalOpen = true;
                this.isCreateModalOpen = false;
            },

            closeModal() {
                this.isEditMode = false;
                this.editedDish = null;
                this.isCreateModalOpen = false;
            },

            saveDish(data) {
                if (this.isEditMode) {
                    this.updateDishById(data);
                } else {
                    this.addDish(data);
                }
            },
        },

        async mounted() {
            try {
                await this.getAllDishes();
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
    };
</script>

<style lang="scss" scoped>
    .dishes-container {
        padding-bottom: 30px;

        .title {
            display: none;
        }

        .filter-section {
            margin: 20px 0;
        }

        .empty__wrapper {
            @include column-align-center-justify-center;

            min-height: 300px;
            width: 100%;
        }

        .card__wrapper {
            margin-top: 14px;

            .card {
                margin-bottom: 25px;
            }
        }
    }

    @include media($md) {
        .dishes-container {
            padding-bottom: 60px;

            .card__wrapper {
                @include row-align-start-justify-between;

                flex-wrap: wrap;

                margin-bottom: 40px;

                .card {
                    width: 49%;
                }
            }
        }
    }

    @include media($lg) {
        .dishes-container {
            .title {
                display: block;
                margin-bottom: 25px;
            }
        }
    }

    @include media($xl) {
        .dishes-container {
            .card__wrapper {
                @include row-align-start-justify-between;
                flex-wrap: wrap;

                margin-top: 20px;

                .card {
                    width: 32%;
                }
            }
        }
    }
</style>
