<template>
    <div class="filter-section">
        <div class="filter-item" :class="{ 'filter-item-lt': $i18n.locale === 'lt' }">
            <h5>{{ $t('common.filters') }}</h5>
            <CRSelect
                class="select"
                :data="mealTypeFilterData"
                :selected="params.mealType"
                @select="handelMealTypeSelect"
            />
        </div>
        <div class="filter-item" :class="{ 'filter-item-lt': $i18n.locale === 'lt' }">
            <h5>{{ $t('common.sortBy') }}</h5>
            <CRSelect
                class="select"
                :data="sortByFilterData"
                :selected="params.selectedSortBy"
                @select="handelSortBySelect"
            />
        </div>
        <MenuButtons class="menu-filters" @selectMenuType="handelSelectMenuType" :menu-type="params.menuType" />
        <div class="checkbox-filter">
            <CRCheckbox
                :checked="params.isEvaluated"
                :value="params.isEvaluated"
                @change="updateCheckBox(!params.isEvaluated, 'isEvaluated')"
            >
                {{ $t('dishes.hideNotEvaluatedDishes') }}
            </CRCheckbox>
        </div>
        <div class="checkbox-filter">
            <CRCheckbox
                :checked="params.isUnique"
                :value="params.isUnique"
                @change="updateCheckBox(!params.isUnique, 'isUnique')"
            >
                {{ $t('dishes.uniqueMenuDish') }}
            </CRCheckbox>
        </div>
        <div class="checkbox-filter">
            <CRButton class="btn" pattern="thin" @click="reset">
                {{ $t('buttons.resetToDefault') }}
            </CRButton>
        </div>
    </div>
</template>

<script>
    import CRSelect from '@/components/Common/Select/CRSelect.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRCheckbox from '@/components/Common/Checkbox/CRCheckbox.vue';
    import MenuButtons from '@/components/Common/Buttons/MenuButtons.vue';
    import { sortByFilterData, mealTypeFilterData } from '@/config/dishes/dishes';
    import { mapActions, mapGetters } from 'vuex';
    import { throttle } from 'lodash';

    export default {
        name: 'FilterSection',
        components: { CRSelect, CRButton, CRCheckbox, MenuButtons },
        data() {
            return {
                sortByFilterData,
                mealTypeFilterData,
            };
        },
        computed: {
            ...mapGetters('dish', ['params']),
        },
        methods: {
            ...mapActions('dish', ['getAllDishes']),

            async handelMealTypeSelect(item) {
                try {
                    await this.getAllDishes({ mealType: item.value });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async updateCheckBox(value, field) {
                try {
                    await this.getAllDishes({ [field]: value });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async handelSortBySelect(item) {
                try {
                    await this.getAllDishes({
                        sortField: item.field,
                        sortDirection: item.fieldValue,
                        selectedSortBy: item.value,
                    });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            handelSelectMenuType: throttle(async function (value) {
                try {
                    if (this.params.menuType === value) {
                        await this.getAllDishes({ menuType: '' });
                    } else {
                        await this.getAllDishes({ menuType: value });
                    }
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            }, 1000),

            async reset() {
                try {
                    await this.getAllDishes({
                        mealType: '',
                        menuType: '',
                        isUnique: false,
                        isEvaluated: false,
                        selectedSortBy: 1,
                        sortField: 'created_at',
                        sortDirection: 'desc',
                        page: 1,
                    });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .filter-section {
        .filter-item {
            width: 100%;
            width: 220px;

            margin-bottom: 18px;

            &.filter-item-lt {
                width: 250px;
            }

            h5 {
                margin-bottom: 8px;
            }

            .select {
                width: 100%;
            }
        }

        .menu-filters {
            margin-bottom: 18px;

            &:deep(.btn) {
                pointer-events: initial;
            }
        }

        .checkbox-filter {
            margin-bottom: 16px;
        }
    }

    @include media($lg) {
        .filter-section {
            @include row-align-end;

            flex-wrap: wrap;

            .filter-item {
                margin-bottom: 16px;
                margin-right: 32px;

                h5 {
                    margin-bottom: 8px;
                }

                .select {
                    width: 100%;
                }
            }

            .menu-filters {
                flex-wrap: wrap;

                margin-bottom: 16px;
                margin-right: 32px;
            }

            .checkbox-filter {
                @include row-align-center;

                margin-bottom: 16px;
                margin-right: 32px;

                height: 100%;
            }
        }
    }
</style>
